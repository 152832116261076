export const educationData = [
    {
        id: 1,
        institution: 'University of Toronto',
        course: 'BASc in Computer Engineering',
        startYear: '2015',
        endYear: '2020'
    },
    {
        id: 2,
        institution: 'DPS RK PURAM',
        course: 'High School Diploma',
        startYear: '2011',
        endYear: '2015'
    },
]