export const skillsData = [
    'HTML',
    'Javascript',
    'CSS',
    'React',
    'Java',
    'Python',
    'C++',
    'C',
    'gcp',
    'docker',
    'typescript',
    'bootstrap',
    'mongoDB',
    'mysql',
    'postgresql',
    'tailwind',
    'go',
    'matlab',
    'aws',
    'firebase',
    'git',
    'graphql',
    'materialui',
    'numpy',
    'opencv',
    'pytorch',
    'tensorflow',
    'wordpress',
    'figma',
]
